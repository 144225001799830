
@import '../../../../main.scss';



.footer {
    display: flex;
    color:$theRed;


    a {
        color:$theRed;
    }

    a:hover {
        color:$theRed;
    }

    a:visited {
        color:$theRed;
    }

    a:link {
        color:$theRed;
    }


    .footerLeft {
        flex: 1;

        div {
            padding:7px;
            padding-left: 20px;
        }
    }

    .footerCenter {
        flex:1;
        // background-color: green;
        display: flex;
        max-width: 50px;
        margin-left: 20px;
        a {
            flex:1;
            font-size: 21px;
            text-align: center;
        }
    }

    .footerRight {
        flex: 1;
        
        .buttonGroup {
            float: right;
            margin-top: 5px;
            padding-right: 10px;
            button {
                padding:0px 20px 0px;
                color:$theRed;
                background-color: $theYellow;
                border-color:$theRed;
                margin: 0px;
                margin-left: 10px;
                white-space: nowrap;
                border: $theRed 2px solid;

                &:hover {
                    color:$theYellow;
                    border: 2px $theRed solid;
                    
                    background-color: $theRed;
                }

                &:active {
                    color:$theYellow;
                    border: 2px $theRed solid;
                    background-color: $theRed;
                }
            }
        }

    }


    
}