@import "../../../../main.scss";

.map {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
}

.storiesNearMe {
    position: absolute;
    z-index: 5;
    margin:15px;
    margin-top: 12px;
    
    button {
        background-color: rgba($theYellow, 0.7);
        color: $theRed;
        border: $theRed 2px solid;
        padding:10px;
        border-radius: 5px;
        margin-bottom: 10px;
        
        &:hover {
            color:$theYellow;
            border-color: $theRed;
            background-color:$theRed;
        }
    }
}