@import "../../../../main.scss";

body {
    color: $theRed;
    background-color:$theLightBlue;
    


    .tellStoryButton {
        color: $theRed;
        border: 2px solid $theRed;
        background-color: $theYellow;
        margin: 20px 10px 20px 0px;

        &:hover {
            color:$theYellow !important;
            border: 2px $theRed solid !important;
            background-color: $theRed !important;
        }


        &:active {
            color:$theYellow !important;
            border: 2px $theRed solid !important;
            background-color: $theRed !important;
        }
    }

    p {
        
    }
}

a {
    color: $theRed;
}

a:link {
    color: $theRed;
}

a:hover {
    text-decoration: none;
}

.containerAbout {
    // padding-left: 30px;
    max-width: 80%;
}

.count {
    padding:0px;
    // font-size: 20px;
    margin-left: 50px;;
    a {
        color:$theDarkBlue;
    }
}

.wrapper {
    height: 100%;
   
    .center {
        height: 95%;
        display: flex;

        .sidebar {
            flex: 1;
            height: 100%;
            overflow: scroll;
        }

        .content {
            flex: 1;
        }
    }


    .footer {
        background-color:$theDarkBlue;
        height: 5%;
        width: 100%;
        display: flex;
        color:black;
        div {
            flex: 1;
            padding: 10px;
        }

    }

}

