@import "../../../../main.scss";

.header {
    background-color: $theYellow;
    font-size: 16px;
    overflow: hidden;
    color: $theRed;

    .headerContent {
        display: flex;
        width: 100%;
        background-color: blue;
    }
}

.question {
    font-size: 2.5vmax; 
    line-height: 2.6vmax;
    padding-left:20px;
    padding-top:20px;
}


.title {
    flex: 1.0;
    font-size: 2.0vmax; 
    margin-bottom: 20px;

    a {
        color: $theRed;
    }

    a:link {
        color: $theRed;
    }

    a:hover {
        text-decoration: none;
    }
}

.button {
    flex:1.0;
    float:right;
    padding:20px;
    
    button {
        line-height: 1.8vmax;
        padding: 1.0vmax;
        border: $theRed 2px solid;
        white-space: nowrap;
        font-size: 2.0vmax; 
        &:hover {
            color:$theYellow !important;
            border: 2px $theRed solid !important;
            background-color: $theRed !important;
        }
        &:active {
            color:$theYellow !important;
            border: 2px $theRed solid !important;
            background-color: $theRed !important;
        }
    }
    
}


.about {
    flex: 1; 
    padding:18px;
    padding-right: 0px;
    font-size:  1.5vmin; 
    line-height: 1.9vmin;
}

.count {
    flex: 1; 
    padding:15px;
    font-size: 2.0vmax; 
    text-align: right;
}