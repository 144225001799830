@import "../../../../main.scss";


.hasError {
    border: red;
}

.formElement {
    margin-top:10px;
    margin-bottom: 10px;
    padding:20px;
    border: $theRed solid 2px;
    border-radius: 5px;
    background-color: rgba($theYellow, 0.8);
    color:$theRed;
}

.submitFrame {
    margin: 0px;
    
}

.tellStoryButton {
    color: $theYellow;

    border: 2px solid $theRed;
    background-color: $theRed;
    // padding:10px;
    border-radius: 5px;
    
   
    &:hover {
        color: $theRed;
        
        background-color: $theYellow;
        border: 2px solid $theRed;
    }

    &:disabled,
    &[disabled]{
        border: 2px solid #999999;
        background-color: #cccccc !important;
        color: #666666 !important;
    }

    
}


