@import "../../../main.scss";

.parent {
    background-color: $theYellow;
    color:black;
    height: 100%;
    display: flex; 
    flex-direction: column;
   
    // background-image: url("/resources/image/pattern-144ppi.png");
    // background-size: 10px 10px;


    .header {
        width: 100%;
        background-color:$theYellow;
        color: $theRed;
        box-shadow: 0px 0px 5px gray;
        z-index: 100;
        // height: 80px;
        display: flex;
        .headerItem {
            flex: 1;
        }
        .headerItem:nth-child(1) {
            flex: 1.2;
            // background-color: red;
        }
        .headerItem:nth-child(2) {
            flex: 0.8;
            
            // background-color: green;;
        }
        .headerItem:nth-child(3) {
            // font-family: 'Space Mono', monospace; // 'cooper_hewittmedium', 
            // font-style: italic;
        }
        .headerItem:nth-child(4) {
            // font-family: 'Space Mono', monospace; // 'cooper_hewittmedium', 
            // font-style: italic;
        }
    }

    .center {
        width: 100%;
        // height: 40%;
        flex:0.8;
        // max-height: 87%;
        display: flex;
        flex-grow: 1;
        overflow:auto;
        .left {
            flex:1;
            overflow: scroll;
        }

        .right {
            flex:1;
            position: relative;
        }
    }

    .footer {
        background-color:$theYellow;
        box-shadow: 0px 0px 5px gray;
        width: 100%;
        height: 40px;
        z-index: 100;
    }

    &.mobile {

        

        .center {
            // background-color: green;
            width: 100%;
            height: inherit;
            flex:none;
            // display: flex;
            // flex-grow: 1;
            // flex-direction: column;
            // flex-direction:column-reverse;
            position: relative;


            .left {
                // flex:1;
                overflow: inherit;
            }
           
    
            

            
        }

        .bubble {
            position: fixed;
            width:200px ;
            height:200px ;
            overflow: hidden;
            background-color: white;
            bottom:2vw;
            right:2vw;
            border-radius: 150px;
            // border: 2px solid $theRed;
            box-shadow: 0px 0px 5px gray;

            &.largeMap {
                border-radius: 5px;
                width:96vw ;
                height:50vh ;
            }

            .right {
               
                width: 100px;
                height: 100px;
                
                // background-color: orange;
            }

        }

        .footer {
            padding-bottom: 270px;
        }

    }
}