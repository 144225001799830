@import "../main.scss";

.ListGroup {
    background-color: transparent;
    width: 100%;
}

.ListGroupItem {
    background-color: transparent;
    border:0px;
}

.link:link {
    color: $theRed;
}

.button {
    padding:20px;
    
    button {
        line-height: 1.8vmax;
        padding: 1.0vmax;
        border: $theRed 2px solid;
        white-space: nowrap;
        font-size: 2.0vmax; 
        &:hover {
            color:$theYellow !important;
            border: 2px $theRed solid !important;
            background-color: $theRed !important;
        }


        &:active {
            color:$theYellow !important;
            border: 2px $theRed solid !important;
            background-color: $theRed !important;
        }
    }
    
}

.loadingStories {
    background-color: $theYellow;
    // border:2px $theRed solid;
    text-align: center;
    color:$theRed;
    width: 100%;
    margin:20px;
    margin-top: 12px;
    margin-bottom: 0px;
    border-radius: 6px;
    padding:50px;
    font-size: 1.5vmax;
    opacity: 0.0;
    animation-name: fadeInOut;
    animation-duration: 1500ms;
    letter-spacing: 2px;
    animation-iteration-count: infinite;

}

@keyframes fadeInOut {
    0%   {opacity: 0.0; letter-spacing: 2px;}
    50%  {opacity: 1.0; letter-spacing: 10px;}
    100% {opacity: 0.0; letter-spacing: 2px;}
  }


.noStories {
    background-color: $theYellow;
    border:2px $theRed solid;
    text-align: center;
    color:$theRed;
    width: 100%;
    margin:20px;
    margin-top: 12px;
    margin-bottom: 0px;
    border-radius: 6px;
    padding:50px;
    font-size: 1.5vmax;
}

.link:visited {
    color: $theRed;
}

.link:hover {
    color: $theRed ;
    text-decoration: none;
}

.rowstyle {
    height: 100%;
}

.wrapper {
    height: 100%;
    background-image: url("/resources/image/pattern-144ppi.png");
    background-size: 20px 20px;

    

    .center {
        height: 85%;
        display: flex;

        .sidebar {
            flex: 1;
            height: 100%;
            overflow: scroll;
        }

        .content {
            flex: 1;
        }
    }


    

}

