@import url('https://fonts.googleapis.com/css?family=Space+Mono&display=swap');

$theRed: rgb(235,76,59);
$theYellow: rgb(249,247,247);
$theLightBlue: rgb(229,241,253);
$theDarkBlue: rgb(110,152,224);


// @font-face {
//     font-family: 'space_grotesksemibold';
//     src: url('/resources/fonts/webfontkit-SpaceGotesk/spacegrotesk-semibold-webfont.woff2') format('woff2'),
//          url('/resources/fonts/webfontkit-SpaceGotesk/spacegrotesk-semibold-webfont.woff') format('woff');
//     font-weight: normal;
//     font-style: normal;
// }


// @font-face {
//     font-family: 'space_groteskbold';
//     src: url('/resources/fonts/webfontkit-SpaceGotesk/spacegrotesk-bold-webfont.woff2') format('woff2'),
//          url('/resources/fonts/webfontkit-SpaceGotesk/spacegrotesk-bold-webfont.woff') format('woff');
//     font-weight: normal;
//     font-style: normal;

// }




// @font-face {
//     font-family: 'space_grotesklight';
//     src: url('/resources/fonts/webfontkit-SpaceGotesk/spacegrotesk-light-webfont.woff2') format('woff2'),
//          url('/resources/fonts/webfontkit-SpaceGotesk/spacegrotesk-light-webfont.woff') format('woff');
//     font-weight: normal;
//     font-style: normal;

// }




// @font-face {
//     font-family: 'space_groteskmedium';
//     src: url('/resources/fonts/webfontkit-SpaceGotesk/spacegrotesk-medium-webfont.woff2') format('woff2'),
//          url('/resources/fonts/webfontkit-SpaceGotesk/spacegrotesk-medium-webfont.woff') format('woff');
//     font-weight: normal;
//     font-style: normal;

// }




@font-face {
    font-family: 'space_groteskregular';
    src: url('/resources/fonts/webfontkit-SpaceGotesk/spacegrotesk-regular-webfont.woff2') format('woff2'),
         url('/resources/fonts/webfontkit-SpaceGotesk/spacegrotesk-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

// @font-face {
//     font-family: 'cooper_hewittmedium';
//     src: url('/resources/fonts/webfontkit-CooperHewitt/cooperhewitt-medium-webfont.woff2') format('woff2'),
//          url('/resources/fonts/webfontkit-CooperHewitt/cooperhewitt-medium-webfont.woff') format('woff');
//     font-weight: normal;
//     font-style: normal;
// }

// @font-face {
//     font-family: 'cooper_hewittsemibold';
//     src: url('/resources/fonts/webfontkit-CooperHewitt/cooperhewitt-semibold-webfont.woff2') format('woff2'),
//          url('/resources/fonts/webfontkit-CooperHewitt/cooperhewitt-semibold-webfont.woff') format('woff');
//     font-weight: normal;
//     font-style: normal;
// }

.tellStoryButton {
    color: $theRed;
    border: 1px solid $theRed;
    background-color: $theYellow;
    
   
    &:hover {
        
        background-color: $theRed;
        border: 1px solid $theRed;
    }
}

#container {
    height: 100% !important;
    padding:20px;
    overflow:auto;

}

html {
    font-size: 100%
}

body,html {
    margin:0px;
    padding:0px;
    height: 100%;
}

body {
    background-color: $theYellow;
    font-family: 'space_groteskregular', 'Courier New', Courier, monospace;
}

