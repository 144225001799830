@import '../../../../../main.scss';
@import '../../../../App.scss';

.Card {
    // background-color: $theLightBlue;
    // background-color: red;
    // background-color: rgba($theYellow, 0.5);
    // background-color: blue;
    border: 2px solid $theRed;
    border-radius: 6px;
    overflow: hidden;
    padding:0px;

    .CardB {
        padding:0px;
        margin:0px 25px 0px;
        color: $theRed;
        // padding-bottom: 15px;
        // max-height: 30vw;
    
        // background-color: rgba( $theYellow, 0.3);
        // padding-right: 20px;;

        &:hover {
            .locationName {
            }
        }
    }

    a {

        color:$theRed;
    }
    a:link {
        color:$theRed;
    }
    a:hover {
        color:$theRed;
    }

    .locationName {
        
        &:hover {
            // color: black;
            cursor: pointer;
        }
    }


    .storyLocation {
        // position: absolute;
        // right: 0px;
        // background-color: green;
        display: flex;
        // background-color: orange;
        div {
            flex:1;
            &:nth-child(1) {
                padding-top: 3px;
                margin-left: 1px;
            }
            &:nth-child(2) {
                padding-top: 3px;
                text-align: right;
            }
        }
    }
    
    .title {
        padding: 10px 0px 0px;
        font-size: 30px;
    }
    
    .actions {
        // position: absolute;
        // bottom:0px;
        // right:0px;
        // padding-top:50px;
        padding-bottom: 10px;

    }
    
    .handle {
        padding: 0px 0px 0px;
        font-style: italic;
        opacity: 0.5;
        margin-bottom: 20px;
    }
    
    .cardStory {
        white-space: pre-line;
        // font-size: 20px;
        .readmore {
            color:$theRed;
        }
    }
    
    .supportbutton {
        background-color: $theYellow;
        color: $theRed;
        border: $theRed 2px solid;
        svg {
            width: 20px;
            height: 20px;
        }
    
        &:hover {
            color:$theYellow;
            border-color: $theRed;
            background-color:$theRed;
        }

        &:hover {
            color:$theYellow !important;
            border: 2px $theRed solid!important;
            
            background-color: $theRed!important;
        }

        &:active {
            color:$theYellow!important;
            border: 2px $theRed solid!important;
            background-color: $theRed!important;
        }
    }
    
    img {
        // padding:20px;
        // width: 100%;
    }
    
    .mobile {
        .bgImageContainter {
            height: 300px !important;
            .bgImage {
                height:300px !important;
            }
        }
    }
    
    .bgImageContainter {
        height: 30vh;
        width: 100%;
        overflow: hidden;
        background-color: $theLightBlue;
    
        
        .bgImage {
            width: 100%;
            height: 30vh;
    
    
            // background: url('/resources/image/IMG_1666.jpg') no-repeat center center fixed; 
            background-repeat: no-repeat;
            background-size: cover;
            // background-attachment: fixed;
           
            background-position: center center;
            // transform:scale(2.3);
            // transition: transform 200ms;
    
            &:hover {
                // cursor: pointer;   
                cursor: url('/resources/image/min.png') 10 10, pointer;; 	
            }
            
    
            
        }
    
        &.toggled {
            .bgImage {
                background-size: contain;
                &:hover {
                    // cursor: pointer;   
                    cursor: url('/resources/image/plus.png') 10 10, pointer;;	
                }
            }
            // transform:scale(1.0)
        }
    
    }
    
    .sharebutton {
        background-color: $theYellow;
        color: $theRed;
        border: $theRed 2px solid;
        &:hover {
            color:$theYellow;
            border-color: $theRed;
            background-color:$theRed;
        }

       
        &:hover {
            color:$theYellow !important;
            border: 2px $theRed solid!important;
            
            background-color: $theRed!important;
        }

        &:active {
            color:$theYellow!important;
            border: 2px $theRed solid!important;
            background-color: $theRed!important;
        }
         

      
    }
    
    .holder {
        // display: flex;
        // height: 100%;
        // background-color: white;
        
        // .left {
        //     flex: 1;
        //     background-color:white;
        // }
    
        // .right {
        //     flex: 1;
        //     background-color: white;
        // }
    
    }




    &.desktop {
        // background-color: orange;

        .CardB {
            padding:0px;
            margin:0px 10px 0px 0px;
            color: $theRed;
            // padding-bottom: 15px;
            // max-height: 30vw;
        
            // background-color: rgba( $theYellow, 0.3);
            // padding-right: 20px;;
        }

        .bgImageContainter {
            overflow: hidden;
            background-color: $theLightBlue;
        
            
            .bgImage {
                background-color: blue;
                // background: url('/resources/image/IMG_1666.jpg') no-repeat center center fixed; 
                background-repeat: no-repeat;
                background-size: cover;
                // background-attachment: fixed;
               
                background-position: center center;
                // transform:scale(2.3);
                // transition: transform 200ms;
        
                &:hover {
                    // cursor: pointer;   
                    cursor: url('/resources/image/min.png') 10 10, pointer;; 	
                }
                
        
                
            }
        
            &.toggled {
                .bgImage {
                    background-size: contain;
                    &:hover {
                        // cursor: pointer;   
                        cursor: url('/resources/image/plus.png') 10 10, pointer;;	
                    }
                }
                // transform:scale(1.0)
            }
        
        }


    }
}

